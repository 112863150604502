import React, { ReactNode } from 'react';
import { linkStyle } from '../constants';

export interface ExternalLinkProps {
  href: string;
  children: ReactNode;
  target?: '_blank' | '_self';
}

export const ExternalLink = (props: ExternalLinkProps) => {
  const { href, target, children } = props;
  return (
    <a href={href} style={linkStyle} target={target || '_blank'}>
      {children}
    </a>
  );
};
