import React, { useEffect, useContext } from 'react';
import { Link, StaticQuery, graphql, navigate } from 'gatsby';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { Avatar, Grow, Paper, Theme, Typography, useTheme } from '@mui/material';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import { styled } from '@mui/material';
import { Box, BoxProps } from '@mui/system';
import { ListingLoopLogo } from '../assets/icons/listing-loop-logo';
import { IconMenu } from '../assets/icons/menu';
import { IconUserAvatar } from '../assets/icons/user-avatar';
import { UserProfile } from '../interfaces/user';
import { MobileMenu } from './mobile-menu';
import { RoundConatiner } from './primitives/round-conatiner';
import { CAPTIONS_TEXT_COLOR, LL_BACKGROUND_COLOR } from '../colors';
import { LinkItem } from '../interfaces/link';
import { NavbarBreadcrumbs } from './navbar-breadcrumbs';
import { LL_Logo } from '../assets/icons/logo/ll-logo';
import {
  CraftGql_NavigationHeader_Default_Entry,
  CraftGql_NavigationHeader_Default_EntryConnection
} from '../../graphql-types';
import { FlexBox } from './primitives/flex';
import { AuthContext } from '../context/auth';

interface NavbarProps {
  location: any;
  isLoggedIn: boolean;
  user: UserProfile;
  isMobile?: boolean;
  isEmptyBackground?: boolean;
}

const linkStyle = { textDecoration: 'none', color: 'inherit' };

export const appBarRoutes = [
  {
    label: 'Buyers',
    value: 'buyers',
    children: [
      { label: 'Buyers', value: 'buyers', href: '/buyers' },
      { label: 'How it works', value: 'how_it_works', href: '/buyers' }
    ]
  },
  {
    label: 'Latest News',
    value: 'latest_news',
    href: '/how-it-works'
  },
  {
    label: 'FAQs',
    value: 'faqs',
    href: '/faqs'
  },
  {
    label: 'Home Loans',
    value: 'home_loans',
    // href: '/',

    children: [
      { label: 'Home Loans', value: 'home_loans', href: '/home-loans' },
      { label: 'Buy a Home', value: 'buy_a_home', href: '/buy_a_home' },
      { label: 'Refinance', value: 'refinance', href: '/refinance' },
      { label: 'First Home Buyer', value: 'first_home_buyer', href: '/refinance' },
      { label: 'Property Investors', value: 'property_investors', href: '/property_investors' }
    ]
  },
  {
    label: 'Contact Us',
    value: 'contact_us',
    children: [
      { label: 'Contact Us', value: 'contact_us', href: '/contact_us' },
      { label: 'About Us', value: 'about_us', href: '/about_us' }
    ]
  },
  {
    label: 'For Agents',
    value: 'for_agents',
    href: '/'
  }
];

export interface AppBarData {
  allCraftGqlNavigationHeaderDefaultEntry: CraftGql_NavigationHeader_Default_EntryConnection;
}

export interface AppBarCustomProps {
  isScroll?: boolean;
  isEmptyBackground?: boolean;
}
export type AppBarProps = AppBarCustomProps & BoxProps;

const CustomAppBar = styled((props: AppBarProps) => <Box {...props} />)(
  ({
    theme,
    isScroll,
    isEmptyBackground
  }: {
    theme: Theme;
    isScroll?: boolean;
    isEmptyBackground?: boolean;
  }) => ({
    display: 'flex',
    width: '100%',
    top: 0,
    left: 0,
    flexDirection: 'row',
    alignItems: 'center',
    padding: !isEmptyBackground && !isScroll ? '5px 20px' : '5px 20px',
    border: 'none',
    zIndex: 1200,
    transition: 'all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',

    borderBottom: isEmptyBackground
      ? isScroll
        ? 'none'
        : `1px solid ${LL_BACKGROUND_COLOR}`
      : 'none',
    boxShadow: !isScroll
      ? 'none'
      : '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)',

    background: isScroll
      ? theme.palette.common.white
      : isEmptyBackground
      ? theme.palette.common.white
      : 'linear-gradient(to bottom, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 97%)',
    [theme.breakpoints.down('md')]: {
      lineHeight: '30px',
      padding: '15px',
      background: theme.palette.primary.main
    }
  })
);

export const guestOptions = [
  {
    label: 'Login',
    value: 'login',
    href: '/login'
  },
  {
    label: 'Sign up',
    value: 'signUp',
    href: '/signup'
  }
];

export const menuOptions = [
  {
    label: 'My Preferences',
    value: 'myPreferences',
    href: '/profile/preferences'
  },
  {
    label: 'My Account',
    value: 'myAccount',
    href: '/profile/account'
  },
  {
    label: 'Home Loans',
    value: 'homeLoans',
    href: 'https://lendingloop.com.au/'
  },
  {
    label: 'Change Agent Assist',
    value: 'changeAgentAssist',
    href: '/buyer-assist'
  },
  {
    label: 'Welcome Tour',
    value: 'welcomeTour',
    href: '/home'
  },
  {
    label: 'Logout',
    value: 'logout',
    href: '/login'
  }
];

const homeBreadcrumb = { label: 'Home', value: 'home', href: '/' };

export interface MenuOption {
  label: string;
  value: string;
  href: string;
}

interface PreferenceMenuProps {
  isLoggedIn: boolean;
}

export const PreferenceMenu = (props: PreferenceMenuProps) => {
  const { isLoggedIn } = props;
  const { user } = useContext(AuthContext);

  const resultOptions = user ? menuOptions : guestOptions;
  return (
    <List>
      {resultOptions.map((option) => (
        <ListItem disablePadding key={option.value}>
          <ListItemButton>
            <Typography component="span" variant="body2">
              <Link to={option.href} style={{ ...linkStyle }}></Link>
              {option.label}
            </Typography>
          </ListItemButton>
        </ListItem>
      ))}
    </List>
  );
};

export const Navbar = (props: NavbarProps) => {
  const theme: Theme = useTheme();
  const minBreadcrumbValue = 2;
  const { common, secondary } = theme.palette;
  console.log('Props', props.isEmptyBackground, props);
  const { isLoggedIn, user, isMobile, isEmptyBackground, location } = props;
  const [mobileMenuOpen, setMobileMenuOpen] = React.useState<boolean>(false);
  const [profileMenuOpen, setProfileMenuOpen] = React.useState<boolean>(false);

  const [selectedRoute, setSelectedRoute] = React.useState<string>('');

  const [isScroll, setIsScroll] = React.useState<boolean>(false);
  const [colorStyle, setColorStyle] = React.useState(common.white);
  const [breadcrumbs, setBreadcrumbs] = React.useState<LinkItem[]>([]);

  const onClose = () => {
    setMobileMenuOpen(false);
  };

  const [open, setOpen] = React.useState(null);
  const handleClick = (value: string) => {
    if (value === open) {
      setOpen(null);
    } else {
      setOpen(value);
    }
  };
  const changeNavbarColor = () => {
    if (window.scrollY >= 80) {
      setIsScroll(true);
      setColorStyle(secondary.main);
    } else {
      setIsScroll(false);
      setColorStyle(common.white);
    }
  };

  const toggleMenu = () => {
    console.log('OPEN', profileMenuOpen);
    setProfileMenuOpen(!profileMenuOpen);
  };

  const handleCloseMenu = () => {
    if (profileMenuOpen) setProfileMenuOpen(false);
  };

  // removed breadcrumbs
  // useEffect(() => {
  //   console.log(location, 'loc')
  //   if (location) {
  //     let { pathname } = location;
  //     let segments = pathname.split('/');
  //     console.log('segement1', segments);
  //     if (segments[1]) segments = segments.slice(1);
  //     else segments = segments.slice(2);
  //     console.log('segement2', segments);
  //     let computedBreadcrumbs = segments.map((segment) => {
  //       let obj: LinkItem = {} as LinkItem;
  //       obj.value = segment;
  //       console.log(segment,'$$$')
  //       segment = segment.replace(/-/g, ' ');

  //       obj.label = segment;
  //       obj.href = pathname;
  //       return obj;
  //     });
  //     setBreadcrumbs([homeBreadcrumb, ...computedBreadcrumbs]);
  //   }
  // }, [location.pathname]);

  useEffect(() => {
    window.addEventListener('scroll', changeNavbarColor);
    return () => {
      console.log('UNmount');
      removeEventListener('scroll', changeNavbarColor);
    };
  }, []);
  console.log('OPen', isMobile);
  return (
    <StaticQuery
      query={query}
      render={(data: AppBarData) => {
        const routes = data.allCraftGqlNavigationHeaderDefaultEntry.nodes;
        return (
          <>
            <CustomAppBar
              position="fixed"
              isScroll={isScroll}
              isEmptyBackground={isEmptyBackground}
              theme={theme}
            >
              <Typography
                variant="h6"
                component="div"
                sx={{
                  flexGrow: 1,
                  color: colorStyle
                }}
              >
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Link to={'/'}>
                    <ListingLoopLogo
                      height={'31px'}
                      width="auto"
                      fill={
                        isEmptyBackground && !isMobile
                          ? secondary.main
                          : isScroll && !isMobile
                          ? colorStyle
                          : ''
                      }
                      top="50%"
                      position="absolute"
                      transform="translateY(-50%)"
                    />
                  </Link>

                  {/* {isEmptyBackground && breadcrumbs.length > minBreadcrumbValue ? (
                    <Link to={'/'} style = {{position:'relative', display:'inline-block'}}>
                      <LL_Logo height={'31px'} width="auto"   top='50%'
                        position='absolute'
                        transform='translateY(-50%)' />
                    </Link>
                  ) : (
                    <Link to={'/'}>
                      <ListingLoopLogo
                        height={'31px'}
                        width="auto"
                        fill={
                          isEmptyBackground && !isMobile
                            ? secondary.main
                            : isScroll && !isMobile
                            ? colorStyle
                            : ''
                        }
                        top="50%"
                        position="absolute"
                        transform="translateY(-50%)"
                      />
                    </Link>
                  )} */}

                  {/* {!isMobile && isEmptyBackground && breadcrumbs.length > minBreadcrumbValue && (
                    <Box px="30px">
                      <NavbarBreadcrumbs breadcrumbs={breadcrumbs} />
                    </Box>
                  )} */}
                </Box>
              </Typography>

              <Box
                sx={{
                  display: { xs: 'none', md: 'flex' },
                  flexDirection: 'row',
                  alignItems: 'center'
                }}
              >
                {/* {breadcrumbs.length <= minBreadcrumbValue + 1 && ( */}
                <ul>
                  {routes.map((route, i) => {
                    const value = route.title.split(' ').join('_').toLowerCase();
                    return (
                      <li key={i} style={{ listStyle: 'none', display: 'inline' }}>
                        <Link
                          to={route.navigationLink || null}
                          onClick={() => handleClick(value)}
                          style={{
                            color:
                              isEmptyBackground && !isScroll ? CAPTIONS_TEXT_COLOR : colorStyle,
                            textDecoration: 'none',
                            marginLeft: '25px',
                            fontSize: '14px',
                            lineHeight: '80px',
                            fontFamily: 'ARSMaquette'
                          }}
                        >
                          <Box
                            display="inline-flex"
                            alignItems="center"
                            sx={{ cursor: 'pointer', position: 'relative' }}
                            onMouseEnter={() => {
                              if (route.remoteChildren) {
                                setSelectedRoute(value);
                                handleCloseMenu();
                              }
                            }}
                            onMouseLeave={() => setSelectedRoute('')}
                          >
                            {route.title}
                            {route.remoteChildren && route.remoteChildren.length > 0 && (
                              <>
                                {open === value ? (
                                  <ExpandMore
                                    sx={{
                                      color:
                                        isEmptyBackground && !isScroll
                                          ? CAPTIONS_TEXT_COLOR
                                          : colorStyle,
                                      height: '14px',
                                      width: '14px'
                                    }}
                                  />
                                ) : (
                                  <ExpandLess
                                    sx={{
                                      transform: 'rotate(180deg)',
                                      color:
                                        isEmptyBackground && !isScroll
                                          ? CAPTIONS_TEXT_COLOR
                                          : colorStyle,
                                      height: '14px',
                                      width: '14px'
                                    }}
                                  />
                                )}
                                <Grow in={selectedRoute === value}>
                                  <Paper
                                    elevation={3}
                                    sx={{
                                      position: 'absolute',
                                      top: 85,
                                      left: 0,
                                      backgroundColor: 'white',
                                      color: 'black',
                                      borderRadius: '0px 10px 10px 10px',
                                      minWidth: '125px',
                                      whiteSpace: 'nowrap'
                                    }}
                                  >
                                    <List>
                                      {route.remoteChildren.map(
                                        (nestRoute: CraftGql_NavigationHeader_Default_Entry) => {
                                          const nestValue = nestRoute.title
                                            .split(' ')
                                            .join('_')
                                            .toLowerCase();
                                          return (
                                            <ListItem disablePadding key={nestValue}>
                                                <ListItemButton onClick={() => {
                                                  handleClick(nestValue);
                                                  navigate(nestRoute.navigationLink);
                                                }}>
                                                  <Typography component="span" variant="body2">
                                                    {nestRoute.title}
                                                  </Typography>
                                                </ListItemButton>
                                            </ListItem>
                                          );
                                        }
                                      )}
                                    </List>
                                  </Paper>
                                </Grow>
                              </>
                            )}
                          </Box>
                        </Link>
                      </li>
                    );
                  })}
                </ul>
                {/* )} */}
                <RoundConatiner
                  theme={theme}
                  sx={{ cursor: 'pointer' }}
                  isScroll={isScroll}
                  isEmptyBackground={isEmptyBackground}
                  position="relative"
                  onClick={toggleMenu}
                >
                  {user?.profileImg ? (
                    <Avatar src="" />
                  ) : (
                    <IconUserAvatar fill={'#66696e'} height="24px" width="24px" />
                  )}
                  <IconMenu marginLeft="10px" />
                  {profileMenuOpen && (
                    <Grow in={profileMenuOpen}>
                      {/* <ClickAwayListener mouseEvent="onMouseDown" onClickAway={handleClickAway}> */}
                      <Paper
                        elevation={3}
                        sx={{
                          position: 'absolute',
                          top: 70,
                          right: 0,
                          backgroundColor: 'white',
                          color: 'black',
                          borderRadius: '10px 0px 10px 10px',
                          minWidth: '125px',
                          whiteSpace: 'nowrap'
                        }}
                      >
                        <PreferenceMenu isLoggedIn={isLoggedIn} />
                      </Paper>
                      {/* </ClickAwayListener> */}
                    </Grow>
                  )}
                </RoundConatiner>
              </Box>
              <Box
                sx={{ display: { xs: 'block', md: 'none' } }}
                onClick={() => setMobileMenuOpen(true)}
              >
                <IconMenu marginLeft="10px" fill={common.white} cursor="pointer" />
              </Box>
            </CustomAppBar>

            <MobileMenu onClose={onClose} data={routes} menuOpen={mobileMenuOpen} user={user} />
          </>
        );
      }}
    />
  );
};

const query = graphql`
  query NavQuery {
    allCraftGqlNavigationHeaderDefaultEntry(filter: { remoteParent: { id: { eq: null } } }) {
      nodes {
        id
        title
        navigationLink
        remoteChildren {
          ... on CraftGQL_navigationHeader_default_Entry {
            id
            title
            navigationLink
          }
        }
      }
    }
  }
`;
