import React from 'react';
import { IconSvg } from './../icon-props';

export const IconYoutube = (props: React.CSSProperties) => {
  return (
    <IconSvg
      width={props.width || '20.04'}
      height={props.height || '14.053'}
      viewBox="0 0 20.04 14.053"
      fill={props.fill}
      style={{ ...props }}
    >
      <path
        id="Path_585"
        data-name="Path 585"
        d="M1391.323,7111.585q.09.812.12,1.591t.051,1.823q-.021,1.061-.061,1.832c-.027.513-.067,1.041-.12,1.58a9.111,9.111,0,0,1-.24,1.412,2.542,2.542,0,0,1-.641,1.131,2.418,2.418,0,0,1-1.121.65,13.734,13.734,0,0,1-1.882.231q-1.26.09-2.623.121t-3.322.049q-1.963-.019-3.334-.06t-2.622-.12a11.32,11.32,0,0,1-1.892-.24,2.428,2.428,0,0,1-1.121-.651,2.531,2.531,0,0,1-.64-1.131,8.733,8.733,0,0,1-.241-1.4q-.079-.8-.121-1.581c-.026-.52-.047-1.128-.059-1.821.012-.707.033-1.319.059-1.832s.068-1.041.121-1.582a9.31,9.31,0,0,1,.241-1.432,2.564,2.564,0,0,1,.65-1.121,2.465,2.465,0,0,1,1.111-.66,13.713,13.713,0,0,1,1.881-.231q1.263-.09,2.623-.119t3.344-.07q1.962.039,3.322.08t2.623.119a11.3,11.3,0,0,1,1.882.24,2.445,2.445,0,0,1,1.121.651,2.557,2.557,0,0,1,.641,1.111A10.482,10.482,0,0,1,1391.323,7111.585Zm-11.841.39v6.006l5.2-2.982Z"
        transform="translate(-1371.454 -7107.952)"
        fill="#fff"
      />
    </IconSvg>
  );
};
