import AddIcon from '@mui/icons-material/Add';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { Box, Divider, Grid, Theme, Typography, useTheme } from '@mui/material';
import Collapse from '@mui/material/Collapse';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import { SxProps } from '@mui/system';
import { graphql, Link, StaticQuery } from 'gatsby';
import React from 'react';
import { FooterQueryQuery } from '../../graphql-types';
import { CurveSVG } from '../assets/icons/curve';
import { ListingLoopLogo } from '../assets/icons/listing-loop-logo';
import { Pattern1 } from '../assets/icons/pattern';
import { IconFacebook } from '../assets/icons/social/facebook';
import { IconInstagram } from '../assets/icons/social/instagram';
import { IconLinkedIn } from '../assets/icons/social/linkedin';
import { IconTwitter } from '../assets/icons/social/twitter';
import { IconYoutube } from '../assets/icons/social/youtube';
import { IconStar } from '../assets/icons/star';
import { CAPTIONS_TEXT_COLOR, LIGHT_GREY_COLOR } from '../colors';
import { Width80Box } from '../components/primitives/Width80Box';
import { hoverLinkStyle, linkStyle } from '../constants';
import { ExternalLink } from './external-link';
import { MenuItem } from './mobile-menu';
import { Absolute } from './primitives/absolute';
import { FlexBox } from './primitives/flex';
import { PrimaryButton } from './primitives/primary-button';
import { ResponsiveFlexBox } from './primitives/responsive-flex';

export interface FooterLinkType {
  id: string;
  title?: string;
  navigationLink?: string;
}

export interface FooterCollapsableItemProps {
  title: string;
  childrenItems: ({} | FooterLinkType)[];
  variant?: 'mobile' | 'desktop';
}

const FooterLinkGroupTitle = (props: { title: string }) => {
  const { title } = props;
  return (
    <Typography
      variant="caption"
      color={CAPTIONS_TEXT_COLOR}
      className="mb-3 footer-title"
      component="div"
      mb={{ lg: 3, md: 0 }}
      sx={{ textTransform: 'uppercase' }}
    >
      {title}
    </Typography>
  );
};

const FooterLink = (props: { href: string; title: string; sx: SxProps }) => {
  const { title, href, sx } = props;
  return (
    <Link to={href} style={linkStyle}>
      <Typography component="span" variant="body2" lineHeight={1.8} color="white" sx={sx}>
        {title}
      </Typography>
    </Link>
  );
};

const FooterCollapsableItem = (props: FooterCollapsableItemProps) => {
  const { title, childrenItems } = props;
  let variant = props.variant || 'desktop';
  const isMobile = variant === 'mobile';
  const [open, setOpen] = React.useState<boolean>(false);
  const toggle = () => {
    setOpen(!open);
  };

  return (
    <Box pl={isMobile ? '16px' : 0}>
      <Typography
        variant={isMobile ? 'caption' : 'body2'}
        lineHeight={1.8}
        color={open ? 'white' : CAPTIONS_TEXT_COLOR}
        component="div"
        onClick={toggle}
        sx={{
          cursor: 'pointer',
          pb: isMobile ? '12px' : '0px',
          display: 'flex',
          justifyContent: 'space-between'
        }}
      >
        {`${title} ${!isMobile ? '+' : ''}`}
        {isMobile && <AddIcon sx={{ fontSize: '20px' }} />}
      </Typography>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {childrenItems.map((route) => (
            <Link
              key={printProp('id', route)}
              to={printProp('navigationLink', route)}
              style={linkStyle}
            >
              <Typography
                component="span"
                variant={isMobile ? 'caption' : 'body2'}
                mr="20px"
                py={isMobile ? '12px' : '0px'}
                lineHeight={1.8}
                color="white"
                sx={hoverLinkStyle}
              >
                {printProp('title', route)}
              </Typography>
            </Link>
          ))}
        </List>
      </Collapse>
    </Box>
  );
};

const printProp = (key: string, route: {} | FooterLinkType) => {
  return 'id' in route ? route[key] : '';
};

const Footer = () => {
  const theme: Theme = useTheme();
  const { common, secondary } = theme.palette;

  /***************** STYLE ********************/
  const sxProps: { [className: string]: SxProps } = {
    wrapper: {
      [theme.breakpoints.down('md')]: {
        marginTop: '30px'
      }
    },
    footerWrapper: {
      bgcolor: secondary.main,
      position: 'relative',
      py: 10,
      '& ul li': {
        listStyle: 'none'
      }
    },

    starIcon: {
      top: '-100px',
      left: 'calc(50% - 60px)',
      height: '60px',
      width: '60px',
      [theme.breakpoints.down('md')]: {
        height: '40px',
        width: '40px'
      }
    },
    pattern: {
      top: '0',
      right: '40%',
      height: '60px',
      width: '60px',
      [theme.breakpoints.down('md')]: {
        height: '40px',
        width: '40px'
      }
    },
    footerButtonContainer: {
      textAlign: 'right',
      [theme.breakpoints.down('md')]: { textAlign: 'center' }
    },
    logoContainer: { [theme.breakpoints.down('md')]: { textAlign: 'center' } },
    footerLink: {
      textDecoration: 'none',
      lineHeight: '28px',
      color: theme.palette.common.white,
      '&:hover': {
        textDecoration: 'underline',
        textDecorationColor: theme.palette.common.white
      }
    },
    copyright: {
      textAlign: 'center',
      fontSize: '12px',
      mt: '50px',
      [theme.breakpoints.down('md')]: { mt: '30px' }
    },
    mobileFooterMenu: { display: 'none', [theme.breakpoints.down('md')]: { display: 'block' } },
    desktopFooterMenu: {
      justifyContent: 'center',
      [theme.breakpoints.down('md')]: { display: 'none' }
    },
    footerBrandSection: { flexGrow: 1, alignItems: 'center', mb: { sm: '30px', md: '70px' } }
  };
  /***************** END STYLE ********************/

  const [open, setOpen] = React.useState(null);
  const handleClick = (value: string) => {
    if (value === open) setOpen(null);
    else setOpen(value);
  };

  /**
   * GraphQL query
   */
  const query = graphql`
    query FooterQuery {
      craftGqlFooterInformationGlobalSet {
        copyrightStatement
        signupLinkFooter
      }
      allCraftGqlNavigationFooterFooterTitleEntry {
        nodes {
          id
          title
          remoteChildren {
            ... on CraftGQL_navigationFooter_footerLink_Entry {
              id
              navigationLink
              title
            }
            ... on CraftGQL_navigationFooter_footerLink_Entry {
              id
              title
              remoteChildren {
                ... on CraftGQL_navigationFooter_footerLink_Entry {
                  id
                  navigationLink
                  title
                }
              }
            }
          }
        }
      }
    }
  `;

  return (
    <StaticQuery
      query={query}
      render={(data: FooterQueryQuery) => {
        let footerMenu = data.allCraftGqlNavigationFooterFooterTitleEntry.nodes;
        const { signupLinkFooter, copyrightStatement } = data.craftGqlFooterInformationGlobalSet;

        return (
          <Box sx={sxProps.wrapper}>
            <CurveSVG width="100%" height="100%" marginBottom="-10px" fill={secondary.main} />
            <Box sx={sxProps.footerWrapper}>
              <Absolute sx={sxProps.starIcon}>
                <IconStar height="100%" width="100%" />
              </Absolute>
              <Absolute sx={sxProps.pattern}>
                <Pattern1 color="primary" height="100%" width="100%" />
              </Absolute>
              <Width80Box mx={{ md: 'auto' }}>
                <Grid container spacing={2} sx={sxProps.footerBrandSection}>
                  <Grid item xs={12} md={6} sx={sxProps.logoContainer}>
                    <Typography variant="h5" sx={{ color: common.white }}>
                      <ListingLoopLogo />
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={6} sx={sxProps.footerButtonContainer}>
                    <ExternalLink href={signupLinkFooter}>
                      <PrimaryButton variant="contained">Sign up</PrimaryButton>
                    </ExternalLink>
                  </Grid>
                </Grid>

                {/* Desktop Footer Menu */}

                <Grid container sx={sxProps.desktopFooterMenu}>
                  {footerMenu.map((node, i) => (
                    <Grid key={node.id} item xs={12} md={3} lg={3} mb={{ lg: 4, md: 0 }}>
                      <FooterLinkGroupTitle title={node.title} />

                      <ul className="list-unstyled footer-text">
                        {node.remoteChildren.map((menuItem, index) => {
                          let menu = 'title' in menuItem ? menuItem : null;
                          return (
                            <li key={menu?.id}>
                              {menu?.remoteChildren.length > 0 ? (
                                <FooterCollapsableItem
                                  title={menu.title}
                                  childrenItems={menu?.remoteChildren}
                                />
                              ) : (
                                <FooterLink
                                  href={menu?.navigationLink}
                                  title={menu?.title}
                                  sx={sxProps.footerLink}
                                />
                              )}
                            </li>
                          );
                        })}
                      </ul>
                    </Grid>
                  ))}
                </Grid>
                {/* Mobile Footer Menu */}
                <Box mt={2} sx={sxProps.mobileFooterMenu}>
                  <List sx={{ width: '100%', px: '16px' }} aria-labelledby="nested-list-subheader">
                    {footerMenu.map((node) => (
                      <React.Fragment key={node.id}>
                        <Divider sx={{ bgcolor: CAPTIONS_TEXT_COLOR }} />
                        <ListItemButton
                          onClick={() => handleClick(node.title)}
                          sx={{ padding: '12px 0' }}
                        >
                          <MenuItem title={node.title} style={{ color: CAPTIONS_TEXT_COLOR }} />
                          {node.remoteChildren &&
                            (open === node.title ? (
                              <ExpandMore sx={{ color: common.white }} />
                            ) : (
                              <ExpandLess
                                sx={{ transform: 'rotate(90deg)', color: common.white }}
                              />
                            ))}
                        </ListItemButton>
                        {node.remoteChildren && (
                          <Collapse in={open === node.title} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                              {node.remoteChildren.map((menu, subIndex) => {
                                return 'remoteChildren' in menu &&
                                  menu.remoteChildren.length > 0 ? (
                                  <FooterCollapsableItem
                                    key={printProp('id', menu)}
                                    title={printProp('title', menu)}
                                    childrenItems={menu.remoteChildren}
                                    variant="mobile"
                                  />
                                ) : (
                                  <ListItemButton key={printProp('id', menu)}>
                                    <MenuItem
                                      title={printProp('title', menu)}
                                      style={{ color: common.white }}
                                    />
                                  </ListItemButton>
                                );
                              })}
                            </List>
                          </Collapse>
                        )}
                      </React.Fragment>
                    ))}
                  </List>
                </Box>

                <Grid
                  container
                  justifyContent="center"
                  mt={{ xs: '30px', md: '100px' }}
                  mb={{ xs: '30px', md: '50px' }}
                >
                  <Grid
                    item
                    xs={12}
                    md={12}
                    lg={6}
                    order={{ xs: 2, md: 2, lg: 1 }}
                    mb={{ lg: 4, md: 0 }}
                  >
                    <ResponsiveFlexBox theme={theme}>
                      <Box mr={{ md: '20px', sm: 0, xs: 0 }} mb={{ md: 0, xs: '15px' }}>
                        <Link to="/" style={linkStyle}>
                          <Typography variant="body2" sx={sxProps.footerLink}>
                            Terms & Conditions
                          </Typography>
                        </Link>
                      </Box>
                      <Box mr={{ md: '20px', sm: 0, xs: 0 }} mb={{ md: 0, xs: '15px' }}>
                        <Link to="/" style={linkStyle}>
                          <Typography variant="body2" sx={sxProps.footerLink}>
                            Privacy Policy
                          </Typography>
                        </Link>
                      </Box>
                      <Box mr={{ md: '20px', sm: 0, xs: 0 }} mb={{ md: 0, xs: '15px' }}>
                        <Link to="/" style={linkStyle}>
                          <Typography variant="body2" sx={sxProps.footerLink}>
                            Privacy Collection
                          </Typography>
                        </Link>
                      </Box>
                      <Box mr={{ md: '20px', sm: 0, xs: 0 }} mb={{ md: 0, xs: '15px' }}>
                        <Link to="/" style={linkStyle}>
                          <Typography variant="body2" sx={sxProps.footerLink}>
                            1300-MY-LOOP / 1300-695-667
                          </Typography>
                        </Link>
                      </Box>
                    </ResponsiveFlexBox>
                  </Grid>
                  <Grid item xs={12} md={12} lg={6} order={{ xs: 1, md: 1, lg: 2 }} mb={4}>
                    <FlexBox alignItems="center" justifyContent={{ xs: 'center', md: 'flex-end' }}>
                      <Link to="https://www.instagram.com/listingloop/" style={linkStyle}>
                        <IconInstagram marginRight="25px" />
                      </Link>
                      <Link to="https://www.facebook.com/ListingLoopAU/" style={linkStyle}>
                        <IconFacebook marginRight="25px" />
                      </Link>

                      <Link to="https://twitter.com/ListingLoop" style={linkStyle}>
                        <IconTwitter marginRight="25px" />
                      </Link>

                      <Link
                        to="https://www.youtube.com/channel/UC02cPX-k2CS8v-k4D4kGNcw"
                        style={linkStyle}
                      >
                        <IconYoutube marginRight="25px" />
                      </Link>

                      <Link to="https://au.linkedin.com/company/listingloop" style={linkStyle}>
                        <IconLinkedIn />
                      </Link>
                    </FlexBox>
                  </Grid>
                </Grid>
                <Divider color={LIGHT_GREY_COLOR} sx={{ opacity: 0.33 }} />
                <Typography
                  component="div"
                  color={CAPTIONS_TEXT_COLOR}
                  sx={sxProps.copyright}
                  variant="body2"
                >
                  {copyrightStatement ||
                    `© LISTING LOOP AUSTRALIA PTY LTD 2021. USED UNDER LICENCE FROM LISTING LOOP GLOBAL
                  PTY LTD. ALL RIGHTS RESERVED. PATENT NO. 2019101303`}
                </Typography>
              </Width80Box>
            </Box>
          </Box>
        );
      }}
    />
  );
};

export default Footer;
