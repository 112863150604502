import React from 'react';
import { IconSvg } from './icon-props';

export const IconClose = (props: React.CSSProperties) => {
  return (
    <IconSvg
      width={props.width || '24px'}
      height={props.height || '24px'}
      viewBox="0 0 512 512"
      fill={props.fill}
      style={{ ...props }}
    >
      <title>Close</title>
      <path
        fill="none"
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="32"
        d="M368 368L144 144M368 144L144 368"
      />
    </IconSvg>
  );
};
