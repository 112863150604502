import { styled } from '@mui/material';
import { Box, Theme } from '@mui/material';

export const Width80Box = styled(Box)(({ theme }: { theme: Theme }) => ({
  width: '96%',
  margin: 'auto',
  maxWidth: '1330px',
  // border: '1px solid red',
  [theme.breakpoints.down('md')]: {
    width: '100%'
  }
}));
