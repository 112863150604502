import { styled } from '@mui/material';
import { Box, Theme } from '@mui/system';

export const ResponsiveFlexBox = styled(Box)(({ theme }: { theme: Theme }) => ({
  display: 'flex',
  alignItems: 'center',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column'
  }
}));
