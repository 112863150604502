import { createTheme } from '@mui/material';

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 768,
      md: 900,
      lg: 1024,
      xl: 1536
    }
  },
  palette: {
    primary: {
      main: '#00a6dd'
    },
    secondary: {
      dark: '#151f35',
      main: '#061e3b'
    }
  },

  // add font sizes for mobile devices for max width 767
  typography: {
    fontFamily: 'Rubik',
    h1: {
      fontWeight: 500,
      fontSize: '47px',
      lineHeight: 1,
      // '@media (max-width:767px)': {
      '@media (max-width:900px)': {
        fontSize: '30px'
      }
    },
    h2: {
      fontSize: '40px',
      '@media (max-width:900px)': {
        fontSize: '24px'
      }
    },
    h3: {
      fontSize: '34px',
      lineHeight: 1,
      '@media (max-width:900px)': {
        fontSize: '22px'
      }
    },
    h4: {
      fontSize: '32px'
    },
    h5: {
      fontSize: '24px',
      '@media (max-width:900px)': {
        fontSize: '18px'
      }
    },
    h6: {
      fontSize: '22px',
      '@media (max-width:900px)': {
        fontSize: '16px'
      }
    },
    subtitle1: {
      fontSize: '20px',
      '@media (max-width:900px)': {
        fontSize: '16px'
      }
    },

    subtitle2: {
      fontSize: '18px',
      '@media (max-width:900px)': {
        fontSize: '16px'
      }
    },
    body1: {
      fontSize: '16px',
      '@media (max-width:900px)': {
        fontSize: '14px'
      }
    },

    body2: {
      fontSize: '14px',
      '@media (max-width:900px)': {
        fontSize: '12px'
      }
    },
    caption: {
      fontSize: '12px',
      '@media (max-width:900px)': {
        fontSize: '14px'
      }
    }
  }
});

export { theme };
