import { styled, Box, BoxProps, Theme } from '@mui/material';
import React from 'react';
export interface RoundContainerOwnProps {
  isScroll?: boolean;
  isEmptyBackground?: boolean;
}
export type RoundContainerProps = RoundContainerOwnProps & BoxProps;

export const RoundConatiner = styled((props: RoundContainerProps) => <Box {...props} />)(
  ({
    isScroll,
    isEmptyBackground,
    theme
  }: {
    isScroll?: boolean;
    isEmptyBackground?: boolean;
    theme: Theme;
  }) => ({
    height: '50px',
    padding: '6px 12px',
    borderRadius: '30px',
    marginLeft: '25px',
    display: 'flex',
    alignItems: 'center',
    color: '#66696e',
    background:
      isScroll || isEmptyBackground ? 'rgba(102, 105, 110,0.1)' : theme.palette.common.white,
    [theme.breakpoints.down('md')]: {
      background: theme.palette.common.white
    }
  })
);
