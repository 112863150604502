import { SxProps } from '@mui/system';
import React from 'react';

export const APP_NAME = 'Listing Loop';
export const imageStyle: React.CSSProperties = {
  width: '100%',
  height: 'auto',
  objectPosition: 'center',
  objectFit: 'cover'
};

export const linkStyle: React.CSSProperties = {
  textDecoration: 'none',
  color: 'inherit'
};
export const disablePointerEvent: React.CSSProperties = {
  textDecoration: 'none',
  color: 'inherit',
  pointerEvents: 'none'
};

export const hoverLinkStyle: SxProps = {
  opacity: 0.8,
  '&:hover': {
    opacity: 1,
    textDecoration: 'underline'
  }
};

export const RECAPTCHA_SITE_KEY = '6LckFYkdAAAAACEO5uijorZ6II-XqF5wlhoMZ3_u';
export const reCaptchaScriptURL = `https://www.google.com/recaptcha/api.js?render=${RECAPTCHA_SITE_KEY}`;
