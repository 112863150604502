export enum UserType {
  buyer = 'buyer',
  agent = 'agent'
}

export interface UserProfile {
  name: string;
  profileImg: string;
  userType: UserType;
}
