import React from 'react';
import { IconSvg } from './icon-props';

export const CurveSVG = (props: React.CSSProperties) => {
  return (
    <IconSvg
      width={props.width || '1598.052'}
      height={props.height || '156.849'}
      viewBox="0 0 1598.052 156.849"
      fill={props.fill}
      style={{ ...props }}
    >
      <path
        id="Path_565"
        data-name="Path 565"
        d="M802.373,6498.276c-509.848,0-761.918-125.55-800.969-146.823V6508.3H1599.456V6352.715C1571.75,6368.357,1325.877,6498.276,802.373,6498.276Z"
        transform="translate(-1.404 -6351.453)"
        fill={props.fill || '#061e3b'}
      />
    </IconSvg>
  );
};
