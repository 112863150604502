// WARNING: the file is auto generated from ./assets/icons/upload.svg file by svgr

import * as React from 'react';

export interface IconProps {
  height?: string;
  width?: string;
  color?: string;
  style?: React.CSSProperties;
}

// tslint:disable:max-line-length
export const IconStar = (props: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || '61.954'}
    height={props.height || '60.563'}
    viewBox="0 0 61.954 60.563"
    style={props.style}
  >
    <path
      id="Path_667"
      data-name="Path 667"
      d="M843.108,6475.288c-2.4,0-4.445-3.413-6.925-7.544-.964-1.608-1.962-3.269-3.065-4.925l-.869-1.312c-.445-.675-.874-1.327-1.189-1.764l-.093.062a23.143,23.143,0,0,1-2.742,1.626c-1.565.782-3.5,1.857-5.555,2.995-6.209,3.444-9.106,4.954-10.8,4.954a2.094,2.094,0,0,1-1.844-1.062c-.851-1.492-.216-2.969,5.222-12.149,1.528-2.579,3.528-5.956,4.27-7.567-.865-.622-2.335-1.609-3.677-2.509-11.415-7.658-13.967-9.954-13.651-12.283a2.459,2.459,0,0,1,1.735-2c2.392-.8,7.855-.5,15.313,0a72.975,72.975,0,0,0,7.89.322c1.2-.581,3.762-6.511,4.994-9.36,1.389-3.214,2.284-5.237,3.152-6.278,1.481-1.777,2.875-1.9,3.79-1.693,2.15.491,3.061,3.1,3.614,5.308.711,2.845,1.963,13.825,2.366,17.456,4.9,1.933,16.345,6.579,18.334,8.568a2.381,2.381,0,0,1,.642,2.392c-.6,1.987-3.6,4.366-12.2,6.107-1.284.26-2.81.569-3.651.783-.263,1.347-.3,3.034-.493,5.467-.789,9.869-1.28,12.894-3.013,13.934A2.991,2.991,0,0,1,843.108,6475.288Zm-11.832-19.678a2.73,2.73,0,0,1,.536.054c1.348.271,2.017,1.286,3.631,3.738l.858,1.295c1.154,1.731,2.221,3.509,3.163,5.078a51.021,51.021,0,0,0,3.248,5,97.025,97.025,0,0,0,1.152-10.2c.6-7.573.809-8.985,2.017-9.791.641-.427,1.66-.665,5.4-1.423,1.908-.386,5.259-1.064,7.439-1.7a124.583,124.583,0,0,0-12.518-5.444c-.665-.259-4.757-2.379-4.834-3.089-.45-4.16-1.759-15.52-2.4-18.091a11.183,11.183,0,0,0-.715-2.138l-.037.044a36.3,36.3,0,0,0-2.58,5.347c-3.024,6.994-5.008,11.942-7.966,12.311-1.4.176-6.236-1.384-10.671-1.684a80.125,80.125,0,0,0-9.774.25c2.56,2.255,8.077,5.956,10.749,7.749,4.031,2.7,7.312,2.886,7.73,3.513.982,1.473-2.13,3.195-7.162,11.689-1.084,1.831-2.484,4.194-3.527,6.094,1.827-.929,4.06-2.168,5.8-3.132,2.088-1.158,4.06-2.252,5.7-3.072a19.644,19.644,0,0,0,2.32-1.378A4.43,4.43,0,0,1,831.276,6455.61Zm7.4-37.107Z"
      transform="translate(-802.169 -6414.726)"
      fill={props.color || '#00a6dd'}
    />
  </svg>
);
