import { Box, ThemeProvider } from '@mui/system';
import React from 'react';
import { UserProfile, UserType } from '../interfaces/user';
import { theme } from '../theme';
import Footer from './footer';
import { Navbar } from './navbar';

const Layout = ({ children, isNavbarBackgroundEmpty, location }) => {
  const [isMobile, setIsMobile] = React.useState<boolean>(false);
  console.log('Layout render', isNavbarBackgroundEmpty);

  React.useEffect(() => {
    setIsMobile(window.innerWidth < theme.breakpoints.values.md);
    window.addEventListener('scroll', () =>
      setIsMobile(window.innerWidth < theme.breakpoints.values.md)
    );
    return () =>
      removeEventListener('scroll', () =>
        setIsMobile(window.innerWidth < theme.breakpoints.values.md)
      );
  }, []);

  const data: { userDetails: UserProfile } = {
    userDetails: { name: 'John', profileImg: '', userType: UserType.buyer }
  };
  const { userDetails } = data;

  return (
    <ThemeProvider theme={theme}>
      <Box>
        <Navbar
          location={location}
          isLoggedIn
          user={userDetails}
          isMobile={isMobile}
          isEmptyBackground={isNavbarBackgroundEmpty as boolean}
        />
        {children}
      </Box>
      <Footer />
    </ThemeProvider>
  );
};

export default Layout;
