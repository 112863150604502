import React from 'react';
import { IconSvg } from './icon-props';

export const ListingLoopLogo = (props: React.CSSProperties) => {
  return (
    <IconSvg
      width={props.width || '251.901'}
      height={props.height || '44.397'}
      viewBox="0 0 251.901 44.397"
      fill={props.fill}
      style={{ ...props }}
    >
      <g id="Group_286" data-name="Group 286" transform="translate(-164.121 -6534.712)">
        <path
          id="Path_566"
          data-name="Path 566"
          d="M197.221,6550.155l-3.409,1.726-4.11,2.081-1.285.65,0,1.44-.008,4.253-3.894,1.973-1.286.652v9.91l3.407-1.724,9.3-4.7,1.289-.651v-15.608Zm-2.348,3.82v10.344l-9.3,4.7v-4.649l5.177-2.623.01-5.693Z"
          fill={props.fill || '#fff'}
        />
        <path
          id="Path_567"
          data-name="Path 567"
          d="M189.37,6540.984l-3.411,1.72-9.236,4.656-1.29.65,0,1.443-.013,10.42-.005,3.819,3.41-1.719,4.151-2.093,1.288-.65,0-1.442.009-4.365,3.794-1.921,1.285-.651,0-1.44.008-4.607Zm-2.354,3.816-.008,4.607-5.079,2.572-.012,5.805-4.151,2.092.013-10.419Z"
          fill={props.fill || '#fff'}
        />
        <path
          id="Path_568"
          data-name="Path 568"
          d="M186.319,6537.06a19.851,19.851,0,1,0,19.851,19.851,19.873,19.873,0,0,0-19.851-19.851m0,42.049a22.2,22.2,0,1,1,22.2-22.2,22.224,22.224,0,0,1-22.2,22.2"
          fill={props.fill || '#fff'}
        />
        <path
          id="Path_569"
          data-name="Path 569"
          d="M234.761,6566.562a1.656,1.656,0,0,1,.5,1.25,1.612,1.612,0,0,1-.5,1.213,1.751,1.751,0,0,1-1.267.477H221.072a1.684,1.684,0,0,1-1.267-.514,1.737,1.737,0,0,1-.5-1.25v-22.195a1.7,1.7,0,0,1,.514-1.249,1.792,1.792,0,0,1,1.324-.515,1.759,1.759,0,0,1,1.267.515,1.669,1.669,0,0,1,.533,1.249v20.541h10.547a1.752,1.752,0,0,1,1.267.478"
          fill={props.fill || '#fff'}
        />
        <path
          id="Path_570"
          data-name="Path 570"
          d="M238.374,6547.251a1.516,1.516,0,0,1-.459-1.23v-.589a1.455,1.455,0,0,1,.5-1.249,2.56,2.56,0,0,1,1.524-.368,2.3,2.3,0,0,1,1.488.387,1.515,1.515,0,0,1,.459,1.23v.589a1.479,1.479,0,0,1-.477,1.248,2.469,2.469,0,0,1-1.506.368,2.37,2.37,0,0,1-1.525-.386"
          fill={props.fill || '#fff'}
        />
        <path
          id="Path_571"
          data-name="Path 571"
          d="M241.167,6568.988a1.689,1.689,0,0,1-1.268.514,1.644,1.644,0,0,1-1.232-.514,1.737,1.737,0,0,1-.5-1.25v-15.912a1.731,1.731,0,0,1,.5-1.248,1.639,1.639,0,0,1,1.232-.516,1.683,1.683,0,0,1,1.268.516,1.73,1.73,0,0,1,.495,1.248v15.912a1.736,1.736,0,0,1-.495,1.25"
          fill={props.fill || '#fff'}
        />
        <path
          id="Path_572"
          data-name="Path 572"
          d="M244.712,6565.5a1.487,1.487,0,0,1,.771-1.249,1.442,1.442,0,0,1,.846-.257,1.684,1.684,0,0,1,1.213.551,7.734,7.734,0,0,0,2.37,1.874,6.785,6.785,0,0,0,3.031.625,5.192,5.192,0,0,0,2.463-.643,2.285,2.285,0,0,0-.019-3.876,12.269,12.269,0,0,0-3.179-1.213,23.5,23.5,0,0,1-3.418-1.159,6.117,6.117,0,0,1-2.3-1.763,5.166,5.166,0,0,1,0-5.935,6.249,6.249,0,0,1,2.5-1.948,8.24,8.24,0,0,1,3.4-.7,9.816,9.816,0,0,1,3.509.661,6.593,6.593,0,0,1,2.848,2.021,1.621,1.621,0,0,1,.441,1.065,1.581,1.581,0,0,1-.587,1.14,1.326,1.326,0,0,1-.919.33,1.376,1.376,0,0,1-1.066-.44,5.172,5.172,0,0,0-1.893-1.36,6.247,6.247,0,0,0-2.48-.478,4.615,4.615,0,0,0-2.37.57,2.052,2.052,0,0,0-.974,1.929,2.135,2.135,0,0,0,1.121,1.911,14.265,14.265,0,0,0,3.325,1.213,20.536,20.536,0,0,1,3.234,1.1,5.64,5.64,0,0,1,2.168,1.745,4.872,4.872,0,0,1,.882,3.032,4.718,4.718,0,0,1-.992,3,6.4,6.4,0,0,1-2.591,1.948,8.611,8.611,0,0,1-3.4.68,11.169,11.169,0,0,1-4.282-.809,7.979,7.979,0,0,1-3.326-2.573,1.837,1.837,0,0,1-.33-.992"
          fill={props.fill || '#fff'}
        />
        <path
          id="Path_573"
          data-name="Path 573"
          d="M268.679,6553.664v10.95q0,1.839,1.654,1.838a2.642,2.642,0,0,0,.7-.128,2.641,2.641,0,0,1,.7-.129,1.011,1.011,0,0,1,.808.4,1.546,1.546,0,0,1-.551,2.353,3.672,3.672,0,0,1-1.984.551,8.992,8.992,0,0,1-2.224-.258,3.285,3.285,0,0,1-1.8-1.3,5.257,5.257,0,0,1-.79-3.179v-11.1h-2.131a1.573,1.573,0,0,1-1.617-1.617,1.506,1.506,0,0,1,.46-1.139,1.6,1.6,0,0,1,1.157-.442h2.131v-3.086a1.731,1.731,0,0,1,.5-1.249,1.683,1.683,0,0,1,1.267-.515,1.645,1.645,0,0,1,1.232.515,1.735,1.735,0,0,1,.5,1.249v3.086h3.05a1.571,1.571,0,0,1,1.616,1.618,1.509,1.509,0,0,1-.459,1.139,1.6,1.6,0,0,1-1.157.441Z"
          fill={props.fill || '#fff'}
        />
        <path
          id="Path_574"
          data-name="Path 574"
          d="M276.513,6547.251a1.516,1.516,0,0,1-.459-1.23v-.589a1.455,1.455,0,0,1,.5-1.249,2.562,2.562,0,0,1,1.524-.368,2.3,2.3,0,0,1,1.488.387,1.518,1.518,0,0,1,.46,1.23v.589a1.479,1.479,0,0,1-.478,1.248,2.469,2.469,0,0,1-1.506.368,2.37,2.37,0,0,1-1.525-.386"
          fill={props.fill || '#fff'}
        />
        <path
          id="Path_575"
          data-name="Path 575"
          d="M279.306,6568.988a1.688,1.688,0,0,1-1.268.514,1.644,1.644,0,0,1-1.232-.514,1.737,1.737,0,0,1-.5-1.25v-15.912a1.731,1.731,0,0,1,.5-1.248,1.639,1.639,0,0,1,1.232-.516,1.683,1.683,0,0,1,1.268.516,1.727,1.727,0,0,1,.5,1.248v15.912a1.733,1.733,0,0,1-.5,1.25"
          fill={props.fill || '#fff'}
        />
        <path
          id="Path_576"
          data-name="Path 576"
          d="M300.857,6557.743v9.995a1.731,1.731,0,0,1-.5,1.25,1.681,1.681,0,0,1-1.267.514,1.641,1.641,0,0,1-1.232-.514,1.731,1.731,0,0,1-.5-1.25v-9.959a5.51,5.51,0,0,0-.992-3.453,3.83,3.83,0,0,0-3.234-1.287,5.522,5.522,0,0,0-2.664.643,4.9,4.9,0,0,0-1.874,1.728,4.362,4.362,0,0,0-.68,2.369v9.959a1.731,1.731,0,0,1-.5,1.25,1.686,1.686,0,0,1-1.269.514,1.655,1.655,0,0,1-1.248-.5,1.752,1.752,0,0,1-.478-1.268v-15.727a1.734,1.734,0,0,1,.5-1.25,1.639,1.639,0,0,1,1.23-.515,1.686,1.686,0,0,1,1.269.515,1.734,1.734,0,0,1,.5,1.25v1.285a7.893,7.893,0,0,1,2.664-2.48,7.074,7.074,0,0,1,3.656-1.011q6.617,0,6.615,7.938"
          fill={props.fill || '#fff'}
        />
        <path
          id="Path_577"
          data-name="Path 577"
          d="M317.173,6565.773a5.94,5.94,0,0,0,2.186-2.426,7.66,7.66,0,0,0,.79-3.509,7.549,7.549,0,0,0-.79-3.492,6.091,6.091,0,0,0-2.186-2.425,5.871,5.871,0,0,0-3.2-.882,5.8,5.8,0,0,0-3.16.882,6.047,6.047,0,0,0-2.187,2.443,8.03,8.03,0,0,0,0,6.946,6.047,6.047,0,0,0,2.187,2.444,5.788,5.788,0,0,0,3.16.882,5.961,5.961,0,0,0,3.2-.863M323,6550.485a1.764,1.764,0,0,1,.5,1.3v16.169a9.143,9.143,0,0,1-1.268,4.98,7.627,7.627,0,0,1-3.437,2.958,11.993,11.993,0,0,1-4.886.956,13.859,13.859,0,0,1-3.437-.515,6.1,6.1,0,0,1-2.517-1.066q-1.14-.588-1.139-1.469a1.7,1.7,0,0,1,.147-.589,1.391,1.391,0,0,1,1.36-.918,2.79,2.79,0,0,1,1.029.22,13.818,13.818,0,0,0,4.593,1.14,6.492,6.492,0,0,0,4.5-1.415,4.974,4.974,0,0,0,1.561-3.878v-1.984a6.375,6.375,0,0,1-2.591,2.462,7.681,7.681,0,0,1-3.84,1.03,8.683,8.683,0,0,1-4.63-1.286,8.957,8.957,0,0,1-3.288-3.584,11.751,11.751,0,0,1,0-10.326,8.992,8.992,0,0,1,3.27-3.582,8.511,8.511,0,0,1,4.575-1.287,8.365,8.365,0,0,1,6.5,3.161v-1.177a1.818,1.818,0,0,1,.478-1.286,1.629,1.629,0,0,1,1.25-.514,1.717,1.717,0,0,1,1.267.5"
          fill={props.fill || '#fff'}
        />
        <path
          id="Path_578"
          data-name="Path 578"
          d="M351.072,6566.562a1.656,1.656,0,0,1,.5,1.25,1.612,1.612,0,0,1-.5,1.213,1.752,1.752,0,0,1-1.267.477H337.383a1.681,1.681,0,0,1-1.267-.514,1.733,1.733,0,0,1-.5-1.25v-22.195a1.7,1.7,0,0,1,.514-1.249,1.792,1.792,0,0,1,1.323-.515,1.762,1.762,0,0,1,1.268.515,1.669,1.669,0,0,1,.533,1.249v20.541H349.8a1.753,1.753,0,0,1,1.267.478"
          fill={props.fill || '#fff'}
        />
        <path
          id="Path_579"
          data-name="Path 579"
          d="M367.828,6556.328a6.128,6.128,0,0,0-2.241-2.425,6.146,6.146,0,0,0-6.284,0,6.128,6.128,0,0,0-2.241,2.425,7.451,7.451,0,0,0-.827,3.546,7.27,7.27,0,0,0,.827,3.491,6.154,6.154,0,0,0,2.241,2.408,6.149,6.149,0,0,0,6.284,0,6.154,6.154,0,0,0,2.241-2.408,7.282,7.282,0,0,0,.828-3.491,7.463,7.463,0,0,0-.828-3.546m3.032,8.691a9.341,9.341,0,0,1-3.509,3.564,9.994,9.994,0,0,1-9.812,0,9.334,9.334,0,0,1-3.509-3.564,10.274,10.274,0,0,1-1.286-5.145,10.48,10.48,0,0,1,1.286-5.181,9.275,9.275,0,0,1,3.509-3.6,10.01,10.01,0,0,1,9.812,0,9.282,9.282,0,0,1,3.509,3.6,10.48,10.48,0,0,1,1.286,5.181,10.274,10.274,0,0,1-1.286,5.145"
          fill={props.fill || '#fff'}
        />
        <path
          id="Path_580"
          data-name="Path 580"
          d="M389.729,6556.328a6.128,6.128,0,0,0-2.241-2.425,6.148,6.148,0,0,0-6.285,0,6.133,6.133,0,0,0-2.24,2.425,7.451,7.451,0,0,0-.827,3.546,7.27,7.27,0,0,0,.827,3.491,6.159,6.159,0,0,0,2.24,2.408,6.151,6.151,0,0,0,6.285,0,6.154,6.154,0,0,0,2.241-2.408,7.27,7.27,0,0,0,.828-3.491,7.451,7.451,0,0,0-.828-3.546m3.032,8.691a9.334,9.334,0,0,1-3.509,3.564,9.994,9.994,0,0,1-9.812,0,9.334,9.334,0,0,1-3.509-3.564,10.274,10.274,0,0,1-1.286-5.145,10.48,10.48,0,0,1,1.286-5.181,9.275,9.275,0,0,1,3.509-3.6,10.01,10.01,0,0,1,9.812,0,9.275,9.275,0,0,1,3.509,3.6,10.48,10.48,0,0,1,1.286,5.181,10.274,10.274,0,0,1-1.286,5.145"
          fill={props.fill || '#fff'}
        />
        <path
          id="Path_581"
          data-name="Path 581"
          d="M409.811,6565.718a5.954,5.954,0,0,0,2.114-2.408,8.216,8.216,0,0,0,0-6.872,5.949,5.949,0,0,0-2.114-2.407,5.49,5.49,0,0,0-3.049-.882,5.637,5.637,0,0,0-3.087.864,5.779,5.779,0,0,0-2.113,2.407,8.289,8.289,0,0,0,0,6.908,5.775,5.775,0,0,0,2.113,2.408,5.636,5.636,0,0,0,3.087.862,5.485,5.485,0,0,0,3.049-.88m1.875-14.535a8.77,8.77,0,0,1,3.178,3.547,11.191,11.191,0,0,1,1.158,5.145,11.075,11.075,0,0,1-1.158,5.125,8.93,8.93,0,0,1-3.178,3.547,8.12,8.12,0,0,1-4.447,1.285,7.721,7.721,0,0,1-3.564-.844,8.6,8.6,0,0,1-2.683-2.058v8.158a1.732,1.732,0,0,1-.5,1.249,1.688,1.688,0,0,1-1.268.515,1.668,1.668,0,0,1-1.23-.5,1.714,1.714,0,0,1-.5-1.267v-23.115a1.811,1.811,0,0,1,.478-1.285,1.619,1.619,0,0,1,1.248-.515,1.682,1.682,0,0,1,1.268.515,1.774,1.774,0,0,1,.5,1.285v1.066a7.217,7.217,0,0,1,2.573-2.205,7.432,7.432,0,0,1,3.6-.918,8.332,8.332,0,0,1,4.52,1.267"
          fill={props.fill || '#fff'}
        />
      </g>
    </IconSvg>
  );
};
