import React from 'react';
import { Button, ButtonProps } from '@mui/material';
import { styled } from '@mui/material';

export const PrimaryButton = styled((props: ButtonProps) => (
  <Button
    style={{
      borderRadius: '50px',
      color: 'white',
      textTransform: 'none',
      padding: '15px 35px',
      boxShadow: 'none'
    }}
    {...props}
  >
    {props.children}
  </Button>
))(({ theme }) => ({
  textTransform: 'none',
  [theme.breakpoints.down('md')]: {
    padding: '15px 25px',
    fontSize: '13px'
  }
}));
