import React from 'react';
import { IconSvg } from '../icon-props';

export const Pattern2 = (props: React.CSSProperties) => {
  return (
    <IconSvg
      width={props.width || '195.071'}
      height={props.height || '129.638'}
      viewBox="0 0 195.071 129.638"
      fill={props.fill}
      style={{ ...props }}
    >
      <g id="Group_710" transform="translate(-772.932 -4066.786)">
        <g id="Group_338" transform="translate(0 -209.775)">
          <path
            id="Path_646"
            d="M939.826,4315.739a11.675,11.675,0,0,0,9.125-6.716,24.074,24.074,0,0,0,1.835-5.057,47.493,47.493,0,0,0,1.009-5.116,2.284,2.284,0,0,0-.353-1.781,1.612,1.612,0,0,0-1.641-.53.545.545,0,0,0-.139.1,3.218,3.218,0,0,0-.578,1.144,6.646,6.646,0,0,0-.45,1.467,40.369,40.369,0,0,1-1.185,5.5,14.7,14.7,0,0,1-2.3,4.837,9.146,9.146,0,0,1-7.1,3.361c-1.5.1-2.525-.5-3.562.4-.91.778-.7,1.639.522,2.081A9.135,9.135,0,0,0,939.826,4315.739Z"
            fill="#fff"
          />
          <path
            id="Path_647"
            d="M935.018,4315.08a5.688,5.688,0,0,1,.941.223,7.04,7.04,0,0,1,1.444.41c.276.1.538.227.83.3a8.376,8.376,0,0,1,.951.251,8.776,8.776,0,0,1,1.14.474,3.364,3.364,0,0,1,1.047.647,4.209,4.209,0,0,0,1.07.831c.113.049.155.169.211.265a.614.614,0,0,0,.334.34.743.743,0,0,1,.385.513,1.431,1.431,0,0,0,.5.717c.279.219.3.584.444.868.155.312.3.623.439.933a2.234,2.234,0,0,1,.2.835c.01.182-.03.347.2.444a.286.286,0,0,1,.17.287c-.032.374.16.715.116,1.074a10.307,10.307,0,0,0,.153,1.388,1.236,1.236,0,0,1-.011.44.5.5,0,0,0,.081.565.144.144,0,0,1,.031.094c-.111.691.09,1.37-.007,2.049-.08.473-.031.957-.11,1.43-.076.578-.192,1.177-.217,1.772l-.009.1c-.029.646.247.9.952,1a2.367,2.367,0,0,0,1.293-.253.656.656,0,0,0,.36-.41,1.76,1.76,0,0,0,.16-.959.745.745,0,0,1,.287-.78.654.654,0,0,0,.168-.893,1,1,0,0,1-.029-.872,1.539,1.539,0,0,0-.006-1,.406.406,0,0,1,.048-.4,1.126,1.126,0,0,0,.115-.742,12.28,12.28,0,0,1-.26-2.409,2.341,2.341,0,0,0-.514-1.42.225.225,0,0,1-.069-.252,3.574,3.574,0,0,0-.055-1.328c0-.13-.069-.252-.019-.391a.224.224,0,0,0-.058-.225c-.467-.3-.415-.762-.455-1.207-.012-.157-.112-.2-.2-.289a1.4,1.4,0,0,1-.549-.864,1.022,1.022,0,0,0-.557-.762,23.872,23.872,0,0,1-1.856-1.508.522.522,0,0,1-.167-.17,1.583,1.583,0,0,0-.928-.676,9.563,9.563,0,0,1-1.147-.7l-.816-.459a1.247,1.247,0,0,0-.83-.162,1.365,1.365,0,0,1-1.025-.3,3.4,3.4,0,0,0-2.026-.576c-.013,0-.04.01-.053.009a1.111,1.111,0,0,0-1.233.153c-.4-.138-.738.145-1.1.244a1.406,1.406,0,0,0-.685.421c-.3.376-.238.654.205.783A2.728,2.728,0,0,0,935.018,4315.08Z"
            fill="#fff"
          />
          <path
            id="Path_648"
            d="M946.615,4331.278c.044-.372.217-.721.287-1.078.114-.574.34-1.1.505-1.656.118-.314.273-.612.379-.94a8.024,8.024,0,0,1,.288-1.091c.205-.424.335-.88.527-1.3a7.431,7.431,0,0,1,.724-1.184,4.014,4.014,0,0,0,.955-1.15.559.559,0,0,1,.3-.208.683.683,0,0,0,.393-.342c.1-.277.378-.331.591-.391a1.766,1.766,0,0,0,.846-.485c.283-.274.684-.266,1.008-.406.385-.11.749-.261,1.122-.384a3.124,3.124,0,0,1,.985-.136c.208-.008.4.047.534-.175a.354.354,0,0,1,.35-.152c.433.1.853-.108,1.263-.047a10.064,10.064,0,0,0,1.642-.093,1.7,1.7,0,0,1,.518.019.757.757,0,0,0,.665-.047.178.178,0,0,1,.106-.03c.8.107,1.59-.084,2.386.01.553.061,1.117-.008,1.67.053a15.848,15.848,0,0,0,2.067.1l.117,0c.741-.027,1.012-.3,1.022-1.028a2.268,2.268,0,0,0-.436-1.27.72.72,0,0,0-.491-.327,2.151,2.151,0,0,0-1.069-.092.824.824,0,0,1-.874-.244.768.768,0,0,0-.987-.136,1.2,1.2,0,0,1-.953.061,1.827,1.827,0,0,0-1.09.01.423.423,0,0,1-.424-.049,1.311,1.311,0,0,0-.807-.121,12.456,12.456,0,0,1-2.622.164,2.646,2.646,0,0,0-1.593.434.244.244,0,0,1-.277.054,3.656,3.656,0,0,0-1.448-.059.942.942,0,0,1-.428-.011.275.275,0,0,0-.249.031c-.4.433-.886.352-1.38.362-.168,0-.229.1-.338.165a1.832,1.832,0,0,1-1.029.5,1.272,1.272,0,0,0-.928.543,5.75,5.75,0,0,1-.953.969l-.926.971a1.27,1.27,0,0,1-.2.178,2.361,2.361,0,0,0-.778,1.049,14.923,14.923,0,0,1-.785,1.295,7.78,7.78,0,0,0-.507.916,1.74,1.74,0,0,0-.207.9,2.163,2.163,0,0,1-.318,1.14,3.887,3.887,0,0,0-.684,2.224c0,.026.009.04-.006.065a1.289,1.289,0,0,0,.078,1.369c-.155.44.072.823.154,1.219a1.6,1.6,0,0,0,.36.8c.344.379.635.313.795-.192A6.6,6.6,0,0,0,946.615,4331.278Z"
            fill="#fff"
          />
          <path
            id="Path_649"
            d="M948.737,4308.641a12.137,12.137,0,0,0,2.278,5.359,11.348,11.348,0,0,0,4.585,3.415,21.8,21.8,0,0,0,2.541.932c.852.19,1.7.444,2.558.569a45.228,45.228,0,0,0,5.087.592,2.2,2.2,0,0,0,1.716-.5,1.652,1.652,0,0,0,.391-1.679.371.371,0,0,0-.119-.127,3.116,3.116,0,0,0-1.166-.476,5.7,5.7,0,0,0-1.451-.319,35.632,35.632,0,0,1-5.424-.751,13.309,13.309,0,0,1-4.787-1.967,8.7,8.7,0,0,1-3.423-6.819c-.121-1.463.47-2.463-.429-3.474-.78-.884-1.639-.7-2.081.522A8.684,8.684,0,0,0,948.737,4308.641Z"
            fill="#fff"
          />
        </g>
        <path
          id="Path_650"
          d="M897.248,4366.909c-.7,3.365-1.38,6.72-2.064,10.087a2.833,2.833,0,0,0,.308,2.3,2.01,2.01,0,0,0,2.392.528c.84-.283.794-.673.738-1.15a2.359,2.359,0,0,1-.014-.626c.171-1.155.414-2.3.656-3.444q.109-.514.217-1.031c.3-1.455.565-2.585.855-3.819h0c.147-.625.3-1.277.467-2.01a1.4,1.4,0,0,0-.118-.8c-.023-.053-.042-.11-.062-.168a.9.9,0,0,0-.313-.5l-.027-.019h0a.424.424,0,0,0-.111-.063,4.185,4.185,0,0,0-2.05-.054A1.055,1.055,0,0,0,897.248,4366.909Zm-5.288,27.425q-.884,4.584-1.77,9.194a2.4,2.4,0,0,0,.355,2.073,2.162,2.162,0,0,0,2.4.443c.836-.266.78-.614.71-1.047a1.932,1.932,0,0,1-.033-.575c.136-1.054.345-2.1.554-3.141h0c.063-.314.125-.627.186-.941.244-1.275.473-2.273.718-3.346.14-.611.285-1.247.442-1.971a1.179,1.179,0,0,0-.143-.72c-.019-.041-.034-.085-.05-.13h0a.775.775,0,0,0-.335-.464.547.547,0,0,0-.14-.07,4.634,4.634,0,0,0-2.043-.015C892.244,4393.764,892.014,4394.055,891.96,4394.334Zm18.185-5.322c-.793.056-1.586.143-2.38.23a31.112,31.112,0,0,1-4.656.277,1.4,1.4,0,0,1-.61-.177l-.076-.04h0c-.336-.172-.679-.347-.695-1.5-.018-.923.107-.926.352-.932a.841.841,0,0,0,.318-.054,3.9,3.9,0,0,1,1.835-.285c.186.008.376.015.589.007,1.107-.031,1.654-.013,2.366.01h0c.384.013.815.027,1.407.036a32.6,32.6,0,0,1,3.967.25c.535.079.453.423.341.9a4.593,4.593,0,0,0-.1.535c-.071.651-.316.7-.515.7A16.74,16.74,0,0,0,910.145,4389.012Zm-29.252-3.6c.779.233,1.557.466,2.321.729a16.362,16.362,0,0,1,1.953.8c.188.077.428.138.75-.438a4.421,4.421,0,0,1,.309-.452c.292-.391.5-.675.037-.957a35.347,35.347,0,0,0-3.556-1.773c-.579-.252-.985-.44-1.349-.607-.615-.284-1.107-.511-2.108-.9-.183-.073-.344-.15-.5-.224a3.947,3.947,0,0,0-1.83-.469.856.856,0,0,1-.309-.082c-.23-.1-.348-.145-.7.719-.424,1.063-.173,1.362.068,1.65.018.021.036.043.053.065a1.607,1.607,0,0,0,.5.4A30.627,30.627,0,0,0,880.893,4385.414Z"
          transform="translate(0 -209.775)"
          fillRule="evenodd"
        />
        <path
          id="Path_668"
          d="M868.15,4278.256a4.471,4.471,0,0,0-5.677-1.085c-3.384,1.847-2.68,4.815-1.14,6.7s3.907,1.183,5.224.336A4.162,4.162,0,0,0,868.15,4278.256Z"
          transform="translate(0 -209.775)"
          fill="#fff"
        />
        <path
          id="Path_672"
          d="M805.667,4323.22c-1.058,5.109-2.095,10.2-3.134,15.313a4.3,4.3,0,0,0,.468,3.5c.578.957,2.076,1.33,3.631.8,1.275-.431,1.206-1.022,1.121-1.747a3.558,3.558,0,0,1-.021-.95c.259-1.753.627-3.491,1-5.228.111-.521.222-1.043.329-1.565.452-2.209.857-3.925,1.3-5.8h0c.223-.949.456-1.938.71-3.052a2.118,2.118,0,0,0-.18-1.22c-.035-.081-.064-.167-.093-.255a1.361,1.361,0,0,0-.477-.757l-.04-.029h0a.615.615,0,0,0-.169-.1,6.367,6.367,0,0,0-3.113-.082A1.6,1.6,0,0,0,805.667,4323.22Zm-8.028,41.635q-1.341,6.962-2.688,13.959a3.65,3.65,0,0,0,.539,3.148c.592.864,2.1,1.163,3.651.672,1.269-.4,1.183-.931,1.076-1.589a2.965,2.965,0,0,1-.05-.873c.208-1.6.524-3.185.842-4.769h0c.1-.476.19-.952.282-1.428.371-1.936.718-3.452,1.09-5.08.213-.929.433-1.894.671-2.993a1.793,1.793,0,0,0-.217-1.093c-.028-.062-.052-.129-.077-.2h0a1.171,1.171,0,0,0-.51-.7.771.771,0,0,0-.211-.107,7.061,7.061,0,0,0-3.1-.023C798.069,4363.991,797.72,4364.432,797.639,4364.855Zm27.608-8.079c-1.2.085-2.409.217-3.613.349a47.126,47.126,0,0,1-7.069.421,2.116,2.116,0,0,1-.926-.269c-.038-.021-.077-.041-.116-.06h0c-.51-.261-1.031-.527-1.055-2.272-.028-1.4.162-1.407.534-1.416a1.264,1.264,0,0,0,.483-.082,5.909,5.909,0,0,1,2.786-.433c.282.012.571.023.894.011,1.682-.047,2.512-.02,3.593.016h0c.582.019,1.236.04,2.135.054a49.307,49.307,0,0,1,6.024.379c.811.119.687.643.517,1.362a6.96,6.96,0,0,0-.157.812c-.108.988-.479,1.066-.781,1.059A25.188,25.188,0,0,0,825.247,4356.776Zm-44.41-5.462c1.182.353,2.364.707,3.523,1.106a25.188,25.188,0,0,1,2.965,1.214c.285.118.65.21,1.14-.664a6.859,6.859,0,0,1,.468-.687c.443-.593.765-1.024.056-1.452a53.777,53.777,0,0,0-5.4-2.691c-.879-.384-1.5-.668-2.048-.923-.934-.43-1.682-.775-3.2-1.368-.279-.111-.522-.228-.76-.341a5.985,5.985,0,0,0-2.778-.712,1.3,1.3,0,0,1-.47-.124c-.348-.146-.528-.221-1.056,1.092-.643,1.614-.263,2.067.1,2.5l.081.1a2.428,2.428,0,0,0,.763.606A46.739,46.739,0,0,0,780.837,4351.314Z"
          transform="translate(0 -209.775)"
          fill="#fff"
          fillRule="evenodd"
        />
      </g>
    </IconSvg>
  );
};
