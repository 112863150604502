import React from 'react';
import { IconSvg } from './icon-props';

export const IconMenu = (props: React.CSSProperties) => {
  return (
    <IconSvg
      width={props.width || '15.745'}
      height={props.height || '10.75'}
      viewBox="0 0 15.745 10.75"
      fill={props.fill}
      style={{ ...props }}
    >
      <g id="Group_706" data-name="Group 706" transform="translate(-1541.72 -244.779)">
        <line
          id="Line_39"
          data-name="Line 39"
          x2="15.745"
          transform="translate(1541.72 250.154)"
          fill={props.fill || 'none'}
          stroke={props.fill || '#66696e'}
          stroke-miterlimit="10"
          stroke-width="0.75"
        />
        <line
          id="Line_40"
          data-name="Line 40"
          x2="15.745"
          transform="translate(1541.72 255.154)"
          fill={props.fill || 'none'}
          stroke={props.fill || '#66696e'}
          stroke-miterlimit="10"
          stroke-width="0.75"
        />
        <line
          id="Line_41"
          data-name="Line 41"
          x2="15.745"
          transform="translate(1541.72 245.154)"
          fill={props.fill || 'none'}
          stroke={props.fill || '#66696e'}
          stroke-miterlimit="10"
          stroke-width="0.75"
        />
      </g>
    </IconSvg>
  );
};
