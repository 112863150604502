import { styled } from '@mui/system';

export interface LLSVGProps {
  color: 'white' | 'primary' | 'secondary';
  height?: number | string;
  width?: number | string;
}

export const StyledSVG = styled('svg', {
  shouldForwardProp: (prop) => prop !== 'color'
})((props) => ({
  color:
    props.color === 'primary'
      ? props.theme.palette.primary.main
      : props.color === 'secondary'
      ? props.theme.palette.secondary.main
      : props.theme.palette.common.white
}));
