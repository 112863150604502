import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { Avatar, Divider, Grow, Modal, Paper, Theme, useTheme } from '@mui/material';
import Collapse from '@mui/material/Collapse';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import { Box, SxProps } from '@mui/system';
import { navigate } from 'gatsby';
import React, { CSSProperties } from 'react';
import { CraftGql_NavigationHeader_Default_Entry } from '../../graphql-types';
import { IconClose } from '../assets/icons/close';
import { ListingLoopLogo } from '../assets/icons/listing-loop-logo';
import { IconUserAvatar } from '../assets/icons/user-avatar';
import { UserProfile } from '../interfaces/user';
import { PreferenceMenu } from './navbar';
import { FlexBox } from './primitives/flex';
import { RoundConatiner } from './primitives/round-conatiner';

export interface MobileMenuProps {
  menuOpen: boolean;
  onClose: () => void;
  user: UserProfile | null;
  data?: CraftGql_NavigationHeader_Default_Entry[];
}

export const MenuItem = ({
  title,
  style
}: {
  title: string;
  style?: CSSProperties;
}): JSX.Element => {
  const theme: Theme = useTheme();
  return (
    <ListItemText
      primary={title}
      sx={{ color: theme.palette.secondary.dark, fontWeight: 500, ...style }}
    />
  );
};

export const MobileMenu = (props: MobileMenuProps) => {
  const { menuOpen, onClose, user, data: routes } = props;
  const theme: Theme = useTheme();
  const { common, primary } = theme.palette;

  /***************** STYLE ********************/
  const sxProps: { [className: string]: SxProps } = {
    wrapper: {
      height: '100vh',
      width: '100%',
      background: primary.main,
      padding: '15px',
      overflow: 'auto'
    },
    header: { alignItems: 'center', justifyContent: 'space-between', marginBottom: '45px' },
    menuOptionsContainer: { width: '100%', px: '16px' },
    profileMenu: { margin: '30px 20px', flexDirection: 'column', height: 'auto' },
    profileFlex: { alignItems: 'center', padding: '6px 0px', width: '100%' }
  };
  /***************** END STYLE ********************/

  const [open, setOpen] = React.useState(null);
  const [profileMenuOpen, setProfileMenuOpen] = React.useState<boolean>(false);

  const handleClick = (value: string) => {
    if (value === open) {
      setOpen(null);
    } else {
      setOpen(value);
    }
  };

  return (
    <Modal
      open={menuOpen}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={sxProps.wrapper}>
        <FlexBox sx={sxProps.header}>
          <Typography variant="h6" component="div" color={common.white} sx={{ flexGrow: 1 }}>
            <ListingLoopLogo height="31px" width="auto" />
          </Typography>
          <Box onClick={onClose}>
            <IconClose cursor="pointer" color={common.white} />
          </Box>
        </FlexBox>

        <Box mt={2}>
          <List sx={sxProps.menuOptionsContainer} aria-labelledby="nested-list-subheader">
            {routes &&
              routes.map((route, index) => {
                const value = route.title.split(' ').join('_').toLowerCase();
                return (
                  <React.Fragment key={route.id}>
                    <Divider sx={{ bgcolor: 'rgba(255,255,255,0.4)' }} />
                    <ListItemButton
                      onClick={() => {
                        if (route.remoteChildren && route.remoteChildren.length > 0)
                          handleClick(value);
                        else {
                          navigate(route.navigationLink);
                          onClose();
                        }
                      }}
                      sx={{ padding: '12px 0' }}
                    >
                      <MenuItem title={route.title} />
                      {route.remoteChildren &&
                        route.remoteChildren.length > 0 &&
                        (open === value ? (
                          <ExpandMore sx={{ color: common.white }} />
                        ) : (
                          <ExpandLess sx={{ transform: 'rotate(90deg)', color: common.white }} />
                        ))}
                    </ListItemButton>

                    {route.remoteChildren && route.remoteChildren.length > 0 && (
                      <Collapse in={open === value} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                          {route.remoteChildren.map(
                            (subRoute: CraftGql_NavigationHeader_Default_Entry, subIndex) => (
                              <ListItemButton
                                key={subIndex}
                                onClick={() => {
                                  handleClick(value);
                                  navigate(subRoute.navigationLink);
                                  onClose();
                                }}
                              >
                                <MenuItem title={subRoute.title} />
                              </ListItemButton>
                            )
                          )}
                        </List>
                      </Collapse>
                    )}
                  </React.Fragment>
                );
              })}
            <Divider sx={{ bgcolor: 'rgba(255,255,255,0.4)' }} />

            <RoundConatiner theme={theme} isScroll={false} sx={sxProps.profileMenu}>
              <FlexBox
                sx={sxProps.profileFlex}
                onClick={() => setProfileMenuOpen(!profileMenuOpen)}
              >
                {user?.profileImg ? (
                  <Avatar src="" />
                ) : (
                  <IconUserAvatar fill="#66696e" height="30px" width="30px" marginRight="10px" />
                )}
                Profile
              </FlexBox>

              <Collapse in={profileMenuOpen} sx={{ width: '100%' }}>
                <PreferenceMenu isLoggedIn={!!user} />
              </Collapse>
            </RoundConatiner>
          </List>
        </Box>
      </Box>
    </Modal>
  );
};
