import React from 'react';
import { IconSvg } from './../icon-props';

export const IconInstagram = (props: React.CSSProperties) => {
  return (
    <IconSvg
      width={props.width || '19.999'}
      height={props.height || '19.999'}
      viewBox="0 0 19.999 19.999"
      fill={props.fill}
      style={{ ...props }}
    >
      <path
        id="Path_582"
        data-name="Path 582"
        d="M1268.473,7120.844a3.933,3.933,0,0,1-3.983,3.984h-12.032a3.948,3.948,0,0,1-3.984-3.984v-12.032a3.96,3.96,0,0,1,3.984-3.983h12.032a3.945,3.945,0,0,1,3.983,3.983Zm-8.408-10.73a7.658,7.658,0,0,1,1.4.721h5.045v-1a2.833,2.833,0,0,0-.89-2.121,2.986,2.986,0,0,0-2.153-.862h-9.99a2.942,2.942,0,0,0-2.982,2.983v1h5.005a6.715,6.715,0,0,1,1.421-.721,4.512,4.512,0,0,1,1.582-.281A4.245,4.245,0,0,1,1260.065,7110.114Zm5.556,11.861a2.885,2.885,0,0,0,.89-2.112v-8.008h-4a8.5,8.5,0,0,1,.681,1.4,4.484,4.484,0,0,1,.28,1.6,4.723,4.723,0,0,1-.4,1.942,5.415,5.415,0,0,1-1.061,1.582,4.946,4.946,0,0,1-1.562,1.081,4.736,4.736,0,0,1-1.941.4,4.973,4.973,0,0,1-1.962-.4,4.843,4.843,0,0,1-1.6-1.081,5.4,5.4,0,0,1-1.061-1.582,4.724,4.724,0,0,1-.4-1.942,4.485,4.485,0,0,1,.281-1.6,6.945,6.945,0,0,1,.721-1.4H1250.5v8.008a2.936,2.936,0,0,0,.861,2.112,2.833,2.833,0,0,0,2.121.891h9.99A2.936,2.936,0,0,0,1265.621,7121.975Zm-9.28-9.259a2.876,2.876,0,0,0-.88,2.142,2.845,2.845,0,0,0,.88,2.133,3,3,0,0,0,2.163.85,2.962,2.962,0,0,0,2.112-.85,2.828,2.828,0,0,0,.89-2.133,2.859,2.859,0,0,0-.89-2.142,2.941,2.941,0,0,0-2.112-.861A2.976,2.976,0,0,0,1256.341,7112.716Zm6.166-4.865h1.983v1.982h-1.983Z"
        transform="translate(-1248.474 -7104.829)"
        fill="#fff"
      />
    </IconSvg>
  );
};
